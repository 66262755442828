@import "../Assets/Functions.less";
@xxs: ~"(max-width: 450px)";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #e3007c;
@castleDetailsColor: #e3007c;
@pink: #e3007c;
@yellow: #ffde00;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/061/";

.Raleway, .BodyFont {
	font-family: "Raleway", sans-serif;
	font-weight: 400;
}

.LuckiestGuy {
	font-family: 'Luckiest Guy', sans-serif;
	font-weight: 400 !important;
	text-transform: uppercase;
}
.fontSize(@fontSizePx,@scaleXxs: true,@xxsScale: 0.7, @rootFontSizePx: 16,@xxs: ~"(max-width: 450px)" ) {
	font-size: unit(round(@fontSizePx/@rootFontSizePx,3),rem);

	& when(@scaleXxs = true) {
		@media @xxs {
			font-size: unit(round((@fontSizePx * @xxsScale)/@rootFontSizePx,3),rem);
		}
	}
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}
#BodyContent.pageContent.textContent, .lower-content.pageContent.textContent, #NewsContent.pageContent.textContent, #NewsContentPanel.pageContent.textContent {
	padding-top: 0;
	@linkColor: @pink;
	color: #2a3250;
	text-align: center;

	@media @mobile {
		hyphens: manual;
		word-wrap: break-word;
	}

	@media @sm {
		text-align: left;
	}

	a {
		position: relative;
		padding: 0 3px;
		margin: 0 1px;
		display: inline-block;
		color: @linkColor;
		transition: color 0.3s;
		z-index: 1;

		&:hover, &:focus {
			color: #fff;

			&:before {
				transform: scaleY(1);
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: @linkColor;
			transition: transform 0.3s;
			z-index: -1;
			transform-origin: center bottom;
			transform: scaleY(0.1);
		}
	}

	h1, h2 {
		color: @pink;
		.LuckiestGuy;
	}
}

#NewsContentPanel.pageContent.textContent a.thumbnail:before, #NewsContent.pageContent.textContent a.thumbnail:before {
	display: none;
}
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
		.header {
			&__book-online {
				padding-bottom: 0px;
				@media @sm {
					padding-bottom: 50px;
				}

				@media @lg {
					padding-bottom: 20px;
				}

				&:before {
					bottom: 0;
				}
			}

			&__tear {
				width: 100%;
			}
		}
	}

	&--home {
	}
}

.container {
	position: relative;
	width: 100%;

	@media @mobile {
	}

	@media @tablet {
	}

	@media @desktop {
		width: 1300px;
	}
}
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}

.toplinks {
	background: @pink;
	box-shadow: 0 5px 0 rgba(0,0,0,0.15);
	position: relative;
	z-index: 3;

	&__nav {
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 5px;
		color: #fff;
		transition: color 0.3s;
		.fontSize(22);
		font-weight: 700;

		&:hover, &.active, &:focus {
			color: #fff;
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: inherit !important;
		background: @pink !important;
		border-radius: 0;
		z-index: 10000 !important;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.3rem;
		color: #fff !important;
		font-weight: 700 !important;

		&:hover, &:focus, &.active {
			background-color: inherit !important;
			color: #fff !important;
		}
	}
}

.header {
	display: flex;
	padding-bottom: 0;
	flex-direction: column;
	justify-content: center;

	@media @mobile {
		padding-top: 80px;
	}

	@media @sm {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	@media @md {
		justify-content: normal;
		flex-wrap: nowrap;
	}

	&__tall { //Only used when Online Booking is switched off//
		display: flex;
		padding-bottom: 0;
		flex-direction: column;
		justify-content: center;

		@media @mobile {
			padding-top: 80px;
		}

		@media @sm {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding-bottom: 140px;
		}

		@media @md {
			justify-content: normal;
			flex-wrap: nowrap;
		}

		.header {
			&__col-logo {
				align-self: center;
			}

			&__book-online {
				padding-bottom: 30px;
			}
		}
	}

	&__starter-text { //Only visible when online booking switched off
		z-index: 3;
		position: relative;
		.LuckiestGuy;
		color: #FFF;
		.FlexCenter;
		text-align: center;
		padding-bottom: 50px;
		text-shadow: 3px 3px 7px rgba(0,0,0,0.35);

		@media @sm {
			padding: 20px;
		}

		@media @md {
			text-align: left;
			padding: 50px 0 10px 20px;
		}
	}

	&__wrapper {
		position: relative;
		background: linear-gradient(120deg,#6585fd 0%, #e834c3 100%);

		@media @sm {
			background: linear-gradient(120deg,#6585fd 0%, #e834c3 60%);
		}
	}

	&__logo {
		display: block;

		@media @sm {
			width: 80%;
		}
		@media @lg {
			width: 60%;
		}
	}

	&__col {
		&-contact {
			display: flex;
			flex-direction: column;
			padding: 30px 0;
			justify-content: center;
			text-align: center;

			@media @sm {
				justify-content: flex-start;
				text-align: left;
				width: ~'calc(50% - 20px)';
			}

			@media @md {
				width: ~'calc(35% - 20px)';
			}
		}

		&-logo {
			padding: 30px 0;
			align-self: center;

			@media @sm {
				width: ~'calc(50% - 20px)';
			}

			@media @md {
				width: ~'calc(40% - 20px)';
				align-self: flex-start;
			}
		}
	}

	&__phone, &__phone-2, &__email {
		color: #FFF;
		.LuckiestGuy;
		.fontSize(35);
		text-shadow: 3px 3px 7px rgba(0,0,0,0.35);
		display: block;

		@media @lg {
			.fontSize(45);
		}

		&:hover, &.active, &:focus {
			color: #FFF;
		}
	}

	&__email {
		.fontSize(24);
		word-break: break-word;
	}

	&__social-links {
		display: flex;
		flex-direction: row;
		justify-content: center;

		@media @sm {
			justify-content: left;
		}
	}

	&__social-link {
		margin: 5px;
		width: 50px;
	}

	&__book-online {
		position: relative;
		padding-bottom: 40px;

		@media @xs {
			margin-bottom: 20px;
		}

		@media @sm {
			padding-bottom: 130px;
			width: 100%;
		}

		@media @md {
			width: auto;
		}

		@media @lg {
			width: 30%;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(110deg,#5884e8 0%, #2cc7de 100%);
			bottom: 0;
			width: 100%;

			@media @lg {
				width: ~'calc(650px + ((100vw - 1300px)/2))';
				bottom: -140px;
			}
		}
	}

	&__tear {
		position: absolute;
		pointer-events: none;
		background-image: url("@{cloudUrl}tear.png");
		background-size: cover;
		background-position: left top;
		width: 100%;
		height: 55px;
		left: 0;
		bottom: 0;

		@media @lg {
			width: 50%;
		}

		@media @mobile {
			display: none;
		}
	}
}

.book-online {
	position: relative;
	z-index: 3;
	padding: 25px 50px;

	&__widget {
		z-index: 0 !important;
		position: relative;
	}

	@media @mobile {
		padding: 25px 20px;
	}

	&__text {
		.LuckiestGuy;
		color: #fff;
		margin-bottom: 20px;
		text-align: center;
		font-size: 2rem;
		line-height: 1;
		text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.35);

		@media @mobile {
			font-size: 6vw;
		}

		@media @lg {
			font-size: 1.2rem;
		}

		@media @desktop {
			font-size: 1.35rem;
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory, .SearchButton {
		height: 45px;
		border-radius: 10px;
		background: transparent;
		color: #fff;
		border-radius: 2px;
		border: 1px solid #fff;
		padding-left: 15px;
		margin-bottom: 10px;
		display: block;
		width: 100%;
		background-repeat: no-repeat;

		&::placeholder {
			color: #fff;
			opacity: 1;
		}
	}

	#PostcodeSearchLeft option {
		color: #000;
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		background-image: url("@{cloudUrl}icon-area.png");
		background-size: 20px 25px;
		background-position: right 26px center;
	}

	.SearchDate {
		background-image: url("@{cloudUrl}icon-date.png");
		background-size: 25px 25px;
		background-position: right 22px center;
	}

	.SearchCategory {
		background-image: url("@{cloudUrl}icon-product.png");
		background-size: 23px 26px;
		background-position: right 24px center;

		option {
			color: #000;
		}
	}

	.SearchButton {
		padding-left: 0;
		color: #fff;
		.LuckiestGuy;
		background: @pink;
		border: 3px solid @yellow;
		font-size: 1.75rem;
		margin-top: 10px;
		transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s;
		box-shadow: 0 4px 0 #438ce4;
		margin-bottom: 20px;
		text-align: center;
		.FlexCenter;

		&:hover {
			background: @yellow;
			border: 3px solid @pink;
			color: @pink;
			box-shadow: 0;
		}

		@media @mobile {
			height: auto;
		}
	}

	.AreaSearchButton {
		padding-left: 0;
		color: #fff;
		.LuckiestGuy;
		background: @pink;
		border: 3px solid @yellow;
		font-size: 1.75rem;
		height: 45px;
		margin-top: 10px;
		transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s;
		box-shadow: 0 4px 0 #438ce4;
		margin-bottom: 20px;
		text-align: center;
		.FlexCenter;

		&:hover {
			background: @yellow;
			border: 3px solid @pink;
			color: @pink;
			box-shadow: 0;
		}

		@media @mobile {
			height: auto;
		}

		@media @md {
			display: none;
		}
	}
}

.PostcodeMessage {
	bottom: unset;
	top: 0;
}

.banner {
	.BannerPanelSet(24.9850%);
	position: relative;
	z-index: 2;

	@media @sm {
		margin-top: -90px;
	}

	.carousel-indicators {
		display: none;
	}

	&__carousel {
		@media @lg {
			width: ~'calc(100% + ((100vw - 1300px)/2) + 10px)';
		}
	}

	&__controls {
		width: 100%;
		transform: translateY(-100%);
		position: absolute;
		display: flex;
		z-index: 0;

		@media @lg {
			width: 68%;
		}
	}

	&__message {
		color: #fff;
		font-size: 1.10rem;
		background: linear-gradient(to right, #0e5ec1 0%, #e834c3 80%);
		text-align: center;
		padding: 10px 5px;
		flex-grow: 1;
		.FlexCenter;
		position: relative;
		overflow: hidden;

		@media @mobile {
			padding: 40px;
		}

		@media @lg {
			font-size: 1rem;
		}

		@media @desktop {
			font-size: 1.10rem;
		}
	}

	&__text {
		animation: ticker linear infinite 15s;
		transform: translatex(100%);
		width: auto;
		min-width: 150%;
		color: #fff;
		position: absolute;
		.fontSize(18,false);
		right: 0;
		white-space: nowrap;
		margin: 0;

		p {
			margin: 0;
		}
	}

	&__arrows {
		display: flex;
	}

	&__arrow {
		width: 172px;
		min-height: 54px;
		position: relative;
		top: 0;
		left: 0;
		z-index: 10;
		border: none;
		display: block;

		@media @mobile {
			display: none;
		}

		&--prev {
			background: #fff;
			transition: all 0.5s;

			&:before {
				background-color: @pink;
				transform: translate(-50%,-50%);
				transition: all 0.5s;
			}

			&:hover {
				background: @pink;

				&:before {
					background-color: #FFF;
				}
			}
		}

		&--next {
			background: @pink;
			transition: all 0.5s;

			&:before {
				background-color: #fff;
				transform: translate(-50%,-50%) scaleX(-1);
				transition: all 0.5s;
			}

			&:hover {
				background: #FFF;

				&:before {
					background: @pink;
				}
			}
		}

		&:before {
			content: "";
			width: 30px;
			height: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			background-repeat: no-repeat;
			mask-image: url("@{cloudUrl}arrow.png");
			mask-repeat: no-repeat;
			mask-size: 100% 100%;
		}

		.icon {
		}
	}
}

.featured-categories {
	padding: 30px 0;

	&__container {
	}


	&__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	&__link {
		width: 100%;
		margin: 20px 0;
		transition: all 0.5s;

		@media @sm {
			width: ~'calc(50% - 20px)';
		}

		@media @md {
			width: ~'calc(33.3333% - 20px)';
		}

		&:hover, &:focus {
			.featured-categories {
				&__image {
					transform: scale(1.05);
				}

				&__title {
					&:after {
						opacity: 0;
					}
				}

				&__button {
					box-shadow: 0 0 0 rgba(0, 51,242,0.1);
					background: @yellow;
					border: 3px solid @pink;
					color: @pink;
				}
			}
		}
	}

	&__image-panel {
		position: relative;
		padding-top: 66.2921%;
		overflow: hidden;
		transition: all 0.5s;
		margin: 10px 0;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: transform 0.3s;
		transition: all 0.5s;
	}

	&__title {
		.LuckiestGuy;
		color: @pink;
		text-align: center;
		.fontSize(32);
		transition: all 0.5s;
		margin: 10px 0;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			height: 3px;
			background-color: @pink;
			right: 0;
			left: 20%;
			width: 60%;
			background-position: center;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			height: 3px;
			background-color: @yellow;
			right: 0;
			left: 20%;
			width: 60%;
			background-position: center;
			opacity: 1;
			transition: all 0.5s;
		}
	}

	&__description {
		text-align: center;
		color: #2a3250;
		transition: all 0.5s;
		margin: 10px 0;
		.fontSize(16,false);
	}

	&__button {
		display: block;
		color: #FFF;
		.LuckiestGuy;
		.fontSize(28);
		background: @pink;
		border: 3px solid @yellow;
		box-shadow: 0 6px 0 rgba(0,51,242,0.1);
		text-align: center;
		transition: all 0.5s;
		margin: 10px 0;
	}
}

.main-wrapper {
	padding: 30px 0;

	&__row {
		display: flex;
		flex-direction: column-reverse;

		@media @md {
			display: block;
		}
	}

	&__container {
	}

	&__left {
		&-image {
			position: relative;
		}

		&-panel {
			position: relative;
			width: fit-content;
			margin: auto;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transform: translate(20px, 20px);
				z-index: -1;
				background: linear-gradient(to bottom, #e834c3, #6b8de7);
				width: 100%;
				height: 100%;
			}
		}
	}
}
.main-content {
	&__below-products {
		padding: 30px 0;
		position: relative;
		z-index: 2;
		text-align: center;
		.fontSize(16,false);

		@media @sm {
			text-align: left;
		}
	}
}
.page-listings, .PageListings {
	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}
.page-listings {
	&__container {
	}

	&__title {
		.LuckiestGuy;
		text-align: center;
		color: #FFF;
		.fontSize(42);
		margin-bottom: 20px;
	}

	&__bg {
		background: #000;
		padding: 30px 40px 40px;
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
	}
}
.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	display: flex;
	flex-direction: column;
	border: none;
	padding: 10px 10px 20px;
	box-shadow: 0 3px 8px rgba(0,0,0,0.3);
	transition: box-shadow 0.3s;
	.Raleway;
	background: #fff;
	position: relative;
	z-index: 1;

	&:hover {
		box-shadow: 0 2px 2px rgba(0,0,0,0.1);

		.castleCheckBook {
			background: @yellow;
			border: 3px solid @pink;
			color: @pink;
			box-shadow: 0;
		}
	}

	.SingleImagePanel {
		height: auto;
		background-color: transparent;
		padding-top: 0;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}
	}

	.castleTitlePanel {
		padding: 0 5px;
		display: flex;
		margin-top: 10px;
		justify-content: center;
		align-items: center;

		a {
			font-size: 1.35rem;
			color: #2a3250;
		}
	}

	shortProductDescription {
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0 5px;
		margin-top: 10px;
		font-size: 1.5rem;

		span.oldprice {
			font-size: 1rem;
		}

		span.newprice {
			font-size: 1.2rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		height: auto;
		order: 4;
		margin-top: 15px;
		text-transform: uppercase;
		padding-left: 0;
		color: #fff;
		.LuckiestGuy;
		background: @pink;
		border: 3px solid @yellow;
		font-size: 1.25rem;
		padding: 5px 10px;
		.FlexCenter;
		transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s;
		box-shadow: 0 3px 0 0 #e5eafe;
		.FlexCenter();

		&:hover {
		}
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	.fontSize(32);
	.LuckiestGuy;
}

.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
}

.product [itemprop="description"] {
	font-size: 16px;
}

a.BackToCategory {
	@bgColor: #fa0307;
	text-shadow: none;
	border-radius: 4px;
	background-color: @bgColor;

	@media @mobile {
		transform: translateY(30px);
		margin-bottom: 0;
	}

	&:hover, &:focus {
		text-shadow: none;
		background-color: @bgColor;
	}
}

.DetailsLeft {
	background-image: none;
	padding-bottom: 0;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: auto;
		min-width: 200px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.lower-banner {
	position: relative;
	padding: 50px 0;

	@media @md {
		padding: 50px 0 120px;
	}

	&__bg {
		position: absolute;
		bottom: 0;
		object-fit: cover;
		height: 100%;

		@media @md {
			left: 0;
			right: 0;
			height: unset;
		}

		@media (min-width:1500px) {
			object-fit: unset;
			//width: 100%;
			top: 0;
		}
	}

	&__child {
		display: none;
		position: absolute;
		bottom: 3%;
		right: 10%;
		z-index: 1;
		max-width: 42%;

		@media @md {
			display: block;
		}
	}

	&__inner {
		width: 100%;
		padding-top: 63.0145%;
		position: relative;

		img {
			max-width: 100%;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	&__logo {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 30%;
		z-index: 1;
	}
}

.thumbnail-gallery {
	padding: 50px 0;
	position: relative;
	z-index: 2;
	text-align: center;
	background: #FFF;

	&:before {
		content: url("@{cloudUrl}tear.png");
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		transform: translateY(-25%);
	}

	&__text {
		color: @pink;
		.LuckiestGuy;
		.fontSize(45);

		h1, h2, h3 {
			.fontSize(45);
		}
	}

	&__inner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	&__image-panel {
		width: 48%;
		margin: 10px 0;
		position: relative;
		padding-top: 31.5%;
		overflow: hidden;

		@media @md {
			width: 30%;
			padding-top: 19.91%;
		}

		img {
			position: absolute;
			object-fit: cover;
			top: 0;
			left: 0;
		}
	}
}

.area {
	background: linear-gradient(to right, #db3cc9, #6585fd);
	padding: 120px 0 120px;
	position: relative;

	&:before {
		content: url("@{cloudUrl}tear.png");
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		transform: translateY(-78%);
	}

	&__left {
		color: #FFF;
		text-align: center;
		margin: 20px 0;

		@media @md {
			text-align: left;
		}

		h1, h2, h3 {
			.LuckiestGuy;
			.fontSize(30);
		}
	}
}

.testimonials {
	text-align: center;
	padding: 50px 0;
	position: relative;

	&__wrapper {
		position: relative;

		&:before {
			content: url("@{cloudUrl}tear.png");
			top: 0;
			left: 0;
			right: 0;
			position: absolute;
			transform: translateY(-23%);
		}
	}

	&__left {
		margin: 20px 0;
	}

	&__title {
		color: @pink;
		.LuckiestGuy;
		.fontSize(45);
		margin: 20px 0;
	}

	&__inner {
	}

	.RotatorTestimonialRating {
		max-width: 200px;
		margin: 0 auto;
		width: 100%;
		align-self: flex-end;
	}

	.item {
		margin: 0 15px;
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;
	}

	.RotatorTestimonial {
		background: @pink;
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__inner {
		position: relative;
	}

	time {
		display: none;
	}

	a[itemprop="author"] {
		color: #FFF;
		.LuckiestGuy;
		.fontSize(22,false);
		margin: 0;
		color: #FFF;
	}

	p[itemprop="reviewBody"] {
		color: #fff;
		position: relative;
		.Raleway;
		.fontSize(16,false);
	}

	&__left {
	}
}

.footer {
	color: #fff;
	.fontSize(16);

	@media @mobile {
		text-align: center;
	}

	a {
		color: #fff;

		&:hover, &:focus {
			color: #fff;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		background-color: #2a385d;
		background: linear-gradient(to right, #2a385d, #370c2e);
		padding: 20px 0;

		@media @sm {
			flex-direction: row;
		}
	}

	&__bottom {
		background: #000;
		padding: 20px 0;

		&-left {
			text-align: center;

			@media @md {
				text-align: left;
			}
		}

		&-right {
			text-align: center;

			@media @md {
				text-align: right;
			}
		}
	}

	&__links {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__link {
		margin: 0 8px 10px;
		padding: 6px 0;
	}
}
@keyframes ticker {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	100% {
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
	}
}