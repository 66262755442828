.Raleway,
.BodyFont {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
.LuckiestGuy {
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
}
html {
  font-size: 16px;
}
body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
#BodyContent.pageContent.textContent,
.lower-content.pageContent.textContent,
#NewsContent.pageContent.textContent,
#NewsContentPanel.pageContent.textContent {
  padding-top: 0;
  color: #2a3250;
  text-align: center;
}
@media (max-width: 767px) {
  #BodyContent.pageContent.textContent,
  .lower-content.pageContent.textContent,
  #NewsContent.pageContent.textContent,
  #NewsContentPanel.pageContent.textContent {
    -webkit-hyphens: manual;
        -ms-hyphens: manual;
            hyphens: manual;
    word-wrap: break-word;
  }
}
@media (min-width: 768px) {
  #BodyContent.pageContent.textContent,
  .lower-content.pageContent.textContent,
  #NewsContent.pageContent.textContent,
  #NewsContentPanel.pageContent.textContent {
    text-align: left;
  }
}
#BodyContent.pageContent.textContent a,
.lower-content.pageContent.textContent a,
#NewsContent.pageContent.textContent a,
#NewsContentPanel.pageContent.textContent a {
  position: relative;
  padding: 0 3px;
  margin: 0 1px;
  display: inline-block;
  color: #e3007c;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 1;
}
#BodyContent.pageContent.textContent a:hover,
.lower-content.pageContent.textContent a:hover,
#NewsContent.pageContent.textContent a:hover,
#NewsContentPanel.pageContent.textContent a:hover,
#BodyContent.pageContent.textContent a:focus,
.lower-content.pageContent.textContent a:focus,
#NewsContent.pageContent.textContent a:focus,
#NewsContentPanel.pageContent.textContent a:focus {
  color: #fff;
}
#BodyContent.pageContent.textContent a:hover:before,
.lower-content.pageContent.textContent a:hover:before,
#NewsContent.pageContent.textContent a:hover:before,
#NewsContentPanel.pageContent.textContent a:hover:before,
#BodyContent.pageContent.textContent a:focus:before,
.lower-content.pageContent.textContent a:focus:before,
#NewsContent.pageContent.textContent a:focus:before,
#NewsContentPanel.pageContent.textContent a:focus:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
#BodyContent.pageContent.textContent a:before,
.lower-content.pageContent.textContent a:before,
#NewsContent.pageContent.textContent a:before,
#NewsContentPanel.pageContent.textContent a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e3007c;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: -1;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
}
#BodyContent.pageContent.textContent h1,
.lower-content.pageContent.textContent h1,
#NewsContent.pageContent.textContent h1,
#NewsContentPanel.pageContent.textContent h1,
#BodyContent.pageContent.textContent h2,
.lower-content.pageContent.textContent h2,
#NewsContent.pageContent.textContent h2,
#NewsContentPanel.pageContent.textContent h2 {
  color: #e3007c;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
}
#NewsContentPanel.pageContent.textContent a.thumbnail:before,
#NewsContent.pageContent.textContent a.thumbnail:before {
  display: none;
}
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
.outer-wrapper--alt .header__book-online {
  padding-bottom: 0px;
}
@media (min-width: 768px) {
  .outer-wrapper--alt .header__book-online {
    padding-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .outer-wrapper--alt .header__book-online {
    padding-bottom: 20px;
  }
}
.outer-wrapper--alt .header__book-online:before {
  bottom: 0;
}
.outer-wrapper--alt .header__tear {
  width: 100%;
}
.container {
  position: relative;
  width: 100%;
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #e3007c;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
.toplinks {
  background: #e3007c;
  -webkit-box-shadow: 0 5px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px;
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 1.375rem;
  font-weight: 700;
}
@media (max-width: 450px) {
  .toplinks__link {
    font-size: 0.962rem;
  }
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: inherit !important;
  background: #e3007c !important;
  border-radius: 0;
  z-index: 10000 !important;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.3rem;
  color: #fff !important;
  font-weight: 700 !important;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: inherit !important;
  color: #fff !important;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .header {
    -webkit-box-pack: normal;
        -ms-flex-pack: normal;
            justify-content: normal;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}
.header__tall {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header__tall {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .header__tall {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    padding-bottom: 140px;
  }
}
@media (min-width: 992px) {
  .header__tall {
    -webkit-box-pack: normal;
        -ms-flex-pack: normal;
            justify-content: normal;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}
.header__tall .header__col-logo {
  -ms-flex-item-align: center;
      align-self: center;
}
.header__tall .header__book-online {
  padding-bottom: 30px;
}
.header__starter-text {
  z-index: 3;
  position: relative;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #FFF;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding-bottom: 50px;
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.35);
}
@media (min-width: 768px) {
  .header__starter-text {
    padding: 20px;
  }
}
@media (min-width: 992px) {
  .header__starter-text {
    text-align: left;
    padding: 50px 0 10px 20px;
  }
}
.header__wrapper {
  position: relative;
  background: linear-gradient(120deg, #6585fd 0%, #e834c3 100%);
}
@media (min-width: 768px) {
  .header__wrapper {
    background: linear-gradient(120deg, #6585fd 0%, #e834c3 60%);
  }
}
.header__logo {
  display: block;
}
@media (min-width: 768px) {
  .header__logo {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .header__logo {
    width: 60%;
  }
}
.header__col-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
@media (min-width: 768px) {
  .header__col-contact {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    text-align: left;
    width: calc(50% - 20px);
  }
}
@media (min-width: 992px) {
  .header__col-contact {
    width: calc(35% - 20px);
  }
}
.header__col-logo {
  padding: 30px 0;
  -ms-flex-item-align: center;
      align-self: center;
}
@media (min-width: 768px) {
  .header__col-logo {
    width: calc(50% - 20px);
  }
}
@media (min-width: 992px) {
  .header__col-logo {
    width: calc(40% - 20px);
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}
.header__phone,
.header__phone-2,
.header__email {
  color: #FFF;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 2.188rem;
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.35);
  display: block;
}
@media (max-width: 450px) {
  .header__phone,
  .header__phone-2,
  .header__email {
    font-size: 1.531rem;
  }
}
@media (min-width: 1200px) {
  .header__phone,
  .header__phone-2,
  .header__email {
    font-size: 2.813rem;
  }
}
@media (min-width: 1200px) and (max-width: 450px) {
  .header__phone,
  .header__phone-2,
  .header__email {
    font-size: 1.969rem;
  }
}
.header__phone:hover,
.header__phone-2:hover,
.header__email:hover,
.header__phone.active,
.header__phone-2.active,
.header__email.active,
.header__phone:focus,
.header__phone-2:focus,
.header__email:focus {
  color: #FFF;
}
.header__email {
  font-size: 1.5rem;
  word-break: break-word;
}
@media (max-width: 450px) {
  .header__email {
    font-size: 1.05rem;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .header__social-links {
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
}
.header__social-link {
  margin: 5px;
  width: 50px;
}
.header__book-online {
  position: relative;
  padding-bottom: 40px;
}
@media (max-width: 767px) {
  .header__book-online {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .header__book-online {
    padding-bottom: 130px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .header__book-online {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .header__book-online {
    width: 30%;
  }
}
.header__book-online:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(110deg, #5884e8 0%, #2cc7de 100%);
  bottom: 0;
  width: 100%;
}
@media (min-width: 1200px) {
  .header__book-online:before {
    width: calc(650px + ((100vw - 1300px)/2));
    bottom: -140px;
  }
}
.header__tear {
  position: absolute;
  pointer-events: none;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/tear.png");
  background-size: cover;
  background-position: left top;
  width: 100%;
  height: 55px;
  left: 0;
  bottom: 0;
}
@media (min-width: 1200px) {
  .header__tear {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .header__tear {
    display: none;
  }
}
.book-online {
  position: relative;
  z-index: 3;
  padding: 25px 50px;
}
.book-online__widget {
  z-index: 0 !important;
  position: relative;
}
@media (max-width: 767px) {
  .book-online {
    padding: 25px 20px;
  }
}
.book-online__text {
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.35);
}
@media (max-width: 767px) {
  .book-online__text {
    font-size: 6vw;
  }
}
@media (min-width: 1200px) {
  .book-online__text {
    font-size: 1.2rem;
  }
}
@media (min-width: 1330px) {
  .book-online__text {
    font-size: 1.35rem;
  }
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory,
.book-online .SearchButton {
  height: 45px;
  border-radius: 10px;
  background: transparent;
  color: #fff;
  border-radius: 2px;
  border: 1px solid #fff;
  padding-left: 15px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  background-repeat: no-repeat;
}
.book-online #PostcodeSearchLeft select::-webkit-input-placeholder,
.book-online #PostcodeSearchLeft input::-webkit-input-placeholder,
.book-online .SearchDate::-webkit-input-placeholder,
.book-online .SearchCategory::-webkit-input-placeholder,
.book-online .SearchButton::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::-moz-placeholder,
.book-online #PostcodeSearchLeft input::-moz-placeholder,
.book-online .SearchDate::-moz-placeholder,
.book-online .SearchCategory::-moz-placeholder,
.book-online .SearchButton::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::-ms-input-placeholder,
.book-online #PostcodeSearchLeft input::-ms-input-placeholder,
.book-online .SearchDate::-ms-input-placeholder,
.book-online .SearchCategory::-ms-input-placeholder,
.book-online .SearchButton::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::placeholder,
.book-online #PostcodeSearchLeft input::placeholder,
.book-online .SearchDate::placeholder,
.book-online .SearchCategory::placeholder,
.book-online .SearchButton::placeholder {
  color: #fff;
  opacity: 1;
}
.book-online #PostcodeSearchLeft option {
  color: #000;
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/icon-area.png");
  background-size: 20px 25px;
  background-position: right 26px center;
}
.book-online .SearchDate {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/icon-date.png");
  background-size: 25px 25px;
  background-position: right 22px center;
}
.book-online .SearchCategory {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/icon-product.png");
  background-size: 23px 26px;
  background-position: right 24px center;
}
.book-online .SearchCategory option {
  color: #000;
}
.book-online .SearchButton {
  padding-left: 0;
  color: #fff;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  background: #e3007c;
  border: 3px solid #ffde00;
  font-size: 1.75rem;
  margin-top: 10px;
  -webkit-transition: color 0.3s, border 0.3s, background 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-box-shadow: 0 4px 0 #438ce4;
          box-shadow: 0 4px 0 #438ce4;
  margin-bottom: 20px;
  text-align: center;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.book-online .SearchButton:hover {
  background: #ffde00;
  border: 3px solid #e3007c;
  color: #e3007c;
  -webkit-box-shadow: 0;
          box-shadow: 0;
}
@media (max-width: 767px) {
  .book-online .SearchButton {
    height: auto;
  }
}
.book-online .AreaSearchButton {
  padding-left: 0;
  color: #fff;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  background: #e3007c;
  border: 3px solid #ffde00;
  font-size: 1.75rem;
  height: 45px;
  margin-top: 10px;
  -webkit-transition: color 0.3s, border 0.3s, background 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-box-shadow: 0 4px 0 #438ce4;
          box-shadow: 0 4px 0 #438ce4;
  margin-bottom: 20px;
  text-align: center;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.book-online .AreaSearchButton:hover {
  background: #ffde00;
  border: 3px solid #e3007c;
  color: #e3007c;
  -webkit-box-shadow: 0;
          box-shadow: 0;
}
@media (max-width: 767px) {
  .book-online .AreaSearchButton {
    height: auto;
  }
}
@media (min-width: 992px) {
  .book-online .AreaSearchButton {
    display: none;
  }
}
.PostcodeMessage {
  bottom: unset;
  top: 0;
}
.banner {
  position: relative;
  z-index: 2;
}
.banner #CarouselContainer {
  padding-top: 24.985%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
@media (min-width: 768px) {
  .banner {
    margin-top: -90px;
  }
}
.banner .carousel-indicators {
  display: none;
}
@media (min-width: 1200px) {
  .banner__carousel {
    width: calc(100% + ((100vw - 1300px)/2) + 10px);
  }
}
.banner__controls {
  width: 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 0;
}
@media (min-width: 1200px) {
  .banner__controls {
    width: 68%;
  }
}
.banner__message {
  color: #fff;
  font-size: 1.1rem;
  background: -webkit-gradient(linear, left top, right top, from(#0e5ec1), color-stop(80%, #e834c3));
  background: linear-gradient(to right, #0e5ec1 0%, #e834c3 80%);
  text-align: center;
  padding: 10px 5px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .banner__message {
    padding: 40px;
  }
}
@media (min-width: 1200px) {
  .banner__message {
    font-size: 1rem;
  }
}
@media (min-width: 1330px) {
  .banner__message {
    font-size: 1.1rem;
  }
}
.banner__text {
  -webkit-animation: ticker linear infinite 15s;
          animation: ticker linear infinite 15s;
  -webkit-transform: translatex(100%);
          transform: translatex(100%);
  width: auto;
  min-width: 150%;
  color: #fff;
  position: absolute;
  font-size: 1.125rem;
  right: 0;
  white-space: nowrap;
  margin: 0;
}
.banner__text p {
  margin: 0;
}
.banner__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.banner__arrow {
  width: 172px;
  min-height: 54px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  border: none;
  display: block;
}
@media (max-width: 767px) {
  .banner__arrow {
    display: none;
  }
}
.banner__arrow--prev {
  background: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.banner__arrow--prev:before {
  background-color: #e3007c;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.banner__arrow--prev:hover {
  background: #e3007c;
}
.banner__arrow--prev:hover:before {
  background-color: #FFF;
}
.banner__arrow--next {
  background: #e3007c;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.banner__arrow--next:before {
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%) scaleX(-1);
          transform: translate(-50%, -50%) scaleX(-1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.banner__arrow--next:hover {
  background: #FFF;
}
.banner__arrow--next:hover:before {
  background: #e3007c;
}
.banner__arrow:before {
  content: "";
  width: 30px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  -webkit-mask-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/arrow.png");
          mask-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/arrow.png");
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
}
.featured-categories {
  padding: 30px 0;
}
.featured-categories__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.featured-categories__link {
  width: 100%;
  margin: 20px 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
@media (min-width: 768px) {
  .featured-categories__link {
    width: calc(50% - 20px);
  }
}
@media (min-width: 992px) {
  .featured-categories__link {
    width: calc(33.3333% - 20px);
  }
}
.featured-categories__link:hover .featured-categories__image,
.featured-categories__link:focus .featured-categories__image {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.featured-categories__link:hover .featured-categories__title:after,
.featured-categories__link:focus .featured-categories__title:after {
  opacity: 0;
}
.featured-categories__link:hover .featured-categories__button,
.featured-categories__link:focus .featured-categories__button {
  -webkit-box-shadow: 0 0 0 rgba(0, 51, 242, 0.1);
          box-shadow: 0 0 0 rgba(0, 51, 242, 0.1);
  background: #ffde00;
  border: 3px solid #e3007c;
  color: #e3007c;
}
.featured-categories__image-panel {
  position: relative;
  padding-top: 66.2921%;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin: 10px 0;
}
.featured-categories__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.featured-categories__title {
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #e3007c;
  text-align: center;
  font-size: 2rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin: 10px 0;
  position: relative;
}
@media (max-width: 450px) {
  .featured-categories__title {
    font-size: 1.4rem;
  }
}
.featured-categories__title:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: #e3007c;
  right: 0;
  left: 20%;
  width: 60%;
  background-position: center;
}
.featured-categories__title:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: #ffde00;
  right: 0;
  left: 20%;
  width: 60%;
  background-position: center;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.featured-categories__description {
  text-align: center;
  color: #2a3250;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin: 10px 0;
  font-size: 1rem;
}
.featured-categories__button {
  display: block;
  color: #FFF;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 1.75rem;
  background: #e3007c;
  border: 3px solid #ffde00;
  -webkit-box-shadow: 0 6px 0 rgba(0, 51, 242, 0.1);
          box-shadow: 0 6px 0 rgba(0, 51, 242, 0.1);
  text-align: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin: 10px 0;
}
@media (max-width: 450px) {
  .featured-categories__button {
    font-size: 1.225rem;
  }
}
.main-wrapper {
  padding: 30px 0;
}
.main-wrapper__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .main-wrapper__row {
    display: block;
  }
}
.main-wrapper__left-image {
  position: relative;
}
.main-wrapper__left-panel {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.main-wrapper__left-panel:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(20px, 20px);
          transform: translate(20px, 20px);
  z-index: -1;
  background: -webkit-gradient(linear, left top, left bottom, from(#e834c3), to(#6b8de7));
  background: linear-gradient(to bottom, #e834c3, #6b8de7);
  width: 100%;
  height: 100%;
}
.main-content__below-products {
  padding: 30px 0;
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .main-content__below-products {
    text-align: left;
  }
}
@media (max-width: 420px) {
  .page-listings > div,
  .PageListings > div {
    width: 100%;
  }
}
.page-listings__title {
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  text-align: center;
  color: #FFF;
  font-size: 2.625rem;
  margin-bottom: 20px;
}
@media (max-width: 450px) {
  .page-listings__title {
    font-size: 1.837rem;
  }
}
.page-listings__bg {
  background: #000;
  padding: 30px 40px 40px;
  -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  padding: 10px 10px 20px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  background: #fff;
  position: relative;
  z-index: 1;
}
.castlePanel:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.castlePanel:hover .castleCheckBook {
  background: #ffde00;
  border: 3px solid #e3007c;
  color: #e3007c;
  -webkit-box-shadow: 0;
          box-shadow: 0;
}
.castlePanel .SingleImagePanel {
  height: auto;
  background-color: transparent;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
.castlePanel .castleTitlePanel {
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-size: 1.35rem;
  color: #2a3250;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0 5px;
  margin-top: 10px;
  font-size: 1.5rem;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 1rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1.2rem;
}
.castlePanel .castleCheckBook {
  height: auto;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  margin-top: 15px;
  padding-left: 0;
  color: #fff;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  background: #e3007c;
  border: 3px solid #ffde00;
  font-size: 1.25rem;
  padding: 5px 10px;
  -webkit-transition: color 0.3s, border 0.3s, background 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-box-shadow: 0 3px 0 0 #e5eafe;
          box-shadow: 0 3px 0 0 #e5eafe;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #e3007c;
  font-size: 2rem;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
}
@media (max-width: 450px) {
  .DetailsTitle h1,
  .DetailsTitle h2,
  .DetailsMainImage {
    font-size: 1.4rem;
  }
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
}
.product [itemprop="description"] {
  font-size: 16px;
}
a.BackToCategory {
  text-shadow: none;
  border-radius: 4px;
  background-color: #fa0307;
}
@media (max-width: 767px) {
  a.BackToCategory {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    margin-bottom: 0;
  }
}
a.BackToCategory:hover,
a.BackToCategory:focus {
  text-shadow: none;
  background-color: #fa0307;
}
.DetailsLeft {
  background-image: none;
  padding-bottom: 0;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: auto;
  min-width: 200px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.lower-banner {
  position: relative;
  padding: 50px 0;
}
@media (min-width: 992px) {
  .lower-banner {
    padding: 50px 0 120px;
  }
}
.lower-banner__bg {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
@media (min-width: 992px) {
  .lower-banner__bg {
    left: 0;
    right: 0;
    height: unset;
  }
}
@media (min-width: 1500px) {
  .lower-banner__bg {
    -o-object-fit: unset;
       object-fit: unset;
    top: 0;
  }
}
.lower-banner__child {
  display: none;
  position: absolute;
  bottom: 3%;
  right: 10%;
  z-index: 1;
  max-width: 42%;
}
@media (min-width: 992px) {
  .lower-banner__child {
    display: block;
  }
}
.lower-banner__inner {
  width: 100%;
  padding-top: 63.0145%;
  position: relative;
}
.lower-banner__inner img {
  max-width: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.lower-banner__logo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30%;
  z-index: 1;
}
.thumbnail-gallery {
  padding: 50px 0;
  position: relative;
  z-index: 2;
  text-align: center;
  background: #FFF;
}
.thumbnail-gallery:before {
  content: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/tear.png");
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.thumbnail-gallery__text {
  color: #e3007c;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 2.813rem;
}
@media (max-width: 450px) {
  .thumbnail-gallery__text {
    font-size: 1.969rem;
  }
}
.thumbnail-gallery__text h1,
.thumbnail-gallery__text h2,
.thumbnail-gallery__text h3 {
  font-size: 2.813rem;
}
@media (max-width: 450px) {
  .thumbnail-gallery__text h1,
  .thumbnail-gallery__text h2,
  .thumbnail-gallery__text h3 {
    font-size: 1.969rem;
  }
}
.thumbnail-gallery__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.thumbnail-gallery__image-panel {
  width: 48%;
  margin: 10px 0;
  position: relative;
  padding-top: 31.5%;
  overflow: hidden;
}
@media (min-width: 992px) {
  .thumbnail-gallery__image-panel {
    width: 30%;
    padding-top: 19.91%;
  }
}
.thumbnail-gallery__image-panel img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  left: 0;
}
.area {
  background: -webkit-gradient(linear, left top, right top, from(#db3cc9), to(#6585fd));
  background: linear-gradient(to right, #db3cc9, #6585fd);
  padding: 120px 0 120px;
  position: relative;
}
.area:before {
  content: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/tear.png");
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  -webkit-transform: translateY(-78%);
          transform: translateY(-78%);
}
.area__left {
  color: #FFF;
  text-align: center;
  margin: 20px 0;
}
@media (min-width: 992px) {
  .area__left {
    text-align: left;
  }
}
.area__left h1,
.area__left h2,
.area__left h3 {
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 1.875rem;
}
@media (max-width: 450px) {
  .area__left h1,
  .area__left h2,
  .area__left h3 {
    font-size: 1.313rem;
  }
}
.testimonials {
  text-align: center;
  padding: 50px 0;
  position: relative;
}
.testimonials__wrapper {
  position: relative;
}
.testimonials__wrapper:before {
  content: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/061/tear.png");
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  -webkit-transform: translateY(-23%);
          transform: translateY(-23%);
}
.testimonials__left {
  margin: 20px 0;
}
.testimonials__title {
  color: #e3007c;
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 2.813rem;
  margin: 20px 0;
}
@media (max-width: 450px) {
  .testimonials__title {
    font-size: 1.969rem;
  }
}
.testimonials .RotatorTestimonialRating {
  max-width: 200px;
  margin: 0 auto;
  width: 100%;
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.testimonials .item {
  margin: 0 15px;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
}
.testimonials .RotatorTestimonial {
  background: #e3007c;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.testimonials__inner {
  position: relative;
}
.testimonials time {
  display: none;
}
.testimonials a[itemprop="author"] {
  font-family: 'Luckiest Guy', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 1.375rem;
  margin: 0;
  color: #FFF;
}
.testimonials p[itemprop="reviewBody"] {
  color: #fff;
  position: relative;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
.footer {
  color: #fff;
  font-size: 1rem;
}
@media (max-width: 450px) {
  .footer {
    font-size: 0.7rem;
  }
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
.footer a {
  color: #fff;
}
.footer a:hover,
.footer a:focus {
  color: #fff;
}
.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #2a385d;
  background: -webkit-gradient(linear, left top, right top, from(#2a385d), to(#370c2e));
  background: linear-gradient(to right, #2a385d, #370c2e);
  padding: 20px 0;
}
@media (min-width: 768px) {
  .footer__top {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.footer__bottom {
  background: #000;
  padding: 20px 0;
}
.footer__bottom-left {
  text-align: center;
}
@media (min-width: 992px) {
  .footer__bottom-left {
    text-align: left;
  }
}
.footer__bottom-right {
  text-align: center;
}
@media (min-width: 992px) {
  .footer__bottom-right {
    text-align: right;
  }
}
.footer__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footer__link {
  margin: 0 8px 10px;
  padding: 6px 0;
}
@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@keyframes ticker {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
